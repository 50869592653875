import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Elementui from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap'
import 'babel-polyfill'

import promise from 'es6-promise'
// import BaiduMap from 'vue-baidu-map';

//      Vue.use(BaiduMap, {
//        ak: 'a4ITILHn8frRVUlMm0HYcwA0MAhUiFDb'
//      });

promise.polyfill()
Vue.config.productionTip = false
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './assets/css/font-awesome.css'
import './assets/css/animate.min.css'
import './assets/css/swiper-bundle.css'
import './assets/css/jquery.fancybox.min.css'
import './assets/css/style.min.css'
import './assets/css/layer.css'

Vue.use(Elementui)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
