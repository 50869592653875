import Vue from 'vue'
import Router from 'vue-router'
const Index = () => import('../page/index')
const Product = () => import('../page/product')
const applicationSolutions = () => import('../page/application-solutions')
const about = () => import('../page/about')
const contactUs = () => import('../page/contact-us')

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
    },
    {
      path: '/product',
      name: 'Product',
      component: Product,
    },
    {
      path: '/application-solutions',
      name: 'applicationSolutions',
      component: applicationSolutions,
    },
    {
      path: '/about',
      name: 'about',
      component: about,
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: contactUs,
    },
  ],
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
})

export default router
